import { CommonModule } from '@angular/common';
import {
  Component,
  EnvironmentInjector,
  inject,
  runInInjectionContext,
} from '@angular/core';
import { AnalyticsEntity } from '@dendra/entity-analytics';
import { downloadGeometry } from '@dendra/utils';
import { FIT_BOUNDS_OPTS } from '@mydendra/portal/constants';
import { DownloadableResourceService } from '@mydendra/states/downloadable-resource';
import { FeatureClassStatsService } from '@mydendra/states/feature-class-stats';
import { LocationService } from '@mydendra/states/location';
import { RenderableSKAIFeatureService } from '@mydendra/states/renderable-skai-feature';
import { SurveyService } from '@mydendra/states/survey.service';
import { TargetAreaService } from '@mydendra/states/target-area';
import { toBBox2d } from '@mydendra/utils/formats';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { NodeObjectType, TreeNode, TreeService } from '@services/tree.service';
import {
  BaseNodeContextMenuComponent,
  SharedComponentsModule,
} from '@shared/components';
import { MultiPolygon, Polygon } from '@turf/helpers';
import { LngLatBoundsLike } from 'maplibre-gl';
import { BehaviorSubject, Observable, map, of } from 'rxjs';

type DownloadableAction = {
  title: string;
  action: () => void;
};

const openUrl = (url: string) => window.open(url, '_blank');

const boundaryBBox = (boundary: { geometry: Polygon | MultiPolygon }) =>
  toBBox2d(boundary.geometry);

export const NODE_EXTENT: Record<
  Extract<
    | 'SKAIFeature'
    | 'SurveyGroup'
    | 'FeatureClass'
    | 'TargetAreaBoundary'
    | 'SurveyBoundary'
    | 'LocationBoundary'
    | 'HeatMap',
    NodeObjectType
  >,
  (node: TreeNode) => Observable<LngLatBoundsLike | null>
> = {
  SKAIFeature: node =>
    inject(RenderableSKAIFeatureService).query.selectEntity(
      node.objectId,
      layer => layer.extent,
    ),
  SurveyGroup: node => {
    const child = inject(TreeService).getChildren(node)[0];
    return child ? NODE_EXTENT[child.objectType](child) : null;
  },
  FeatureClass: node =>
    inject(FeatureClassStatsService)
      .getStats(node.extraData.location, node.surveyPeriod, node.objectId)
      .pipe(map(stats => stats.bounds)),
  SurveyBoundary: node =>
    inject(SurveyService).getBoundary(node.objectId).pipe(map(boundaryBBox)),
  LocationBoundary: node =>
    inject(LocationService).getBoundary(node.objectId).pipe(map(boundaryBBox)),
  TargetAreaBoundary: node =>
    inject(TargetAreaService)
      .getBoundary(node.objectId)
      .pipe(map(boundaryBBox)),
  // Currently pans to the location extent.  In future, we'll want to use the survey boundary
  HeatMap: node =>
    inject(LocationService)
      .getBoundary(node.extraData.location)
      .pipe(map(boundaryBBox)),
};

const NODE_DOWNLOADABLES: Record<
  string,
  (node: TreeNode) => Observable<DownloadableAction[]>
> = {
  SKAIFeature: node =>
    inject(DownloadableResourceService)
      .listForLayer(node.objectId)
      .pipe(
        map(resources =>
          resources.map(r => ({
            title: r.resource_type_display,
            action: () => openUrl(r.url),
          })),
        ),
      ),
  SurveyGroup: node => {
    const child = inject(TreeService).getChildren(node)[0];
    return NODE_DOWNLOADABLES[child?.objectType]?.(child) ?? of([]);
  },
  SurveyBoundary: node => surveyBoundaryDownloadActions(node),
  LocationBoundary: node => locationBoundaryDownloadActions(node),
  TargetAreaBoundary: node => targetAreaBoundaryDownloadActions(node),
  FeatureClass: node => of(featureClassDownloadActions(node)),
};

@Component({
  selector: 'portal-node-context-menu',
  templateUrl: './context-menu.component.html',
  imports: [CommonModule, SharedComponentsModule, ContextMenuModule],
})
export class NodeContextMenuComponent extends BaseNodeContextMenuComponent {
  private treeService = inject(TreeService);
  private injector = inject(EnvironmentInjector);
  private analyticsService = inject(AnalyticsEntity);

  loadingContextMenuItems = true;
  downloadableResources = new BehaviorSubject<DownloadableAction[]>([]);

  download(resource: DownloadableAction) {
    this.analyticsService.track('Downloaded layer from portal', {
      affected_object_id: this.node.objectId,
      format: resource.title,
      location: this.treeService.query.getAncestorOfType(this.node, 'Location')
        ?.objectId,
    });

    runInInjectionContext(this.injector, resource.action);
  }

  lazyLoadContextMenuItems() {
    // Load some things only when needed
    runInInjectionContext(this.injector, () => {
      // we don't have downloadables for all node types
      const downloadables =
        NODE_DOWNLOADABLES[this.node.objectType] ?? (() => of([]));
      downloadables(this.node).subscribe(resources => {
        this.downloadableResources.next(resources);
        this.loadingContextMenuItems = false;
      });
    });
  }

  panToLayer() {
    runInInjectionContext(this.injector, () => {
      const extent$ = NODE_EXTENT[this.node.objectType](this.node);
      extent$.subscribe(extent =>
        this.mapControlService.map.fitBounds(extent, FIT_BOUNDS_OPTS),
      );
      this.analyticsService.track('Panned to Layer', {
        affected_object_id: this.node.objectId,
      });
    });
  }
}

const featureClassDownloadActions = (node: TreeNode) => {
  const formats = [
    ['geojson', 'GeoJSON'],
    ['kml', 'KML'],
    ['shp', 'Shapefile'],
  ];
  return formats.map(([format, title]) => {
    let url = `/api/data-query/location/${node.extraData.location}/class/${node.objectId}/download/${format}/`;
    if (node.surveyPeriod) {
      url = `${url}?date=${node.surveyPeriod}`;
    }
    return { action: () => openUrl(url), title };
  });
};

const boundaryDownloadActions = (
  boundary: { geometry: Polygon | MultiPolygon },
  name: string,
) => [
  {
    title: 'GeoJSON',
    action: () => downloadGeometry(boundary.geometry, name, 'geojson'),
  },
  {
    title: 'KML',
    action: () => downloadGeometry(boundary.geometry, name, 'kml'),
  },
];

const locationBoundaryDownloadActions = (node: TreeNode) =>
  inject(LocationService)
    .getBoundary(node.objectId)
    .pipe(map(boundary => boundaryDownloadActions(boundary, node.name)));

const surveyBoundaryDownloadActions = (node: TreeNode) =>
  inject(SurveyService)
    .getBoundary(node.objectId)
    .pipe(map(boundary => boundaryDownloadActions(boundary, node.name)));

const targetAreaBoundaryDownloadActions = (node: TreeNode) =>
  inject(TargetAreaService)
    .getBoundary(node.objectId)
    .pipe(map(boundary => boundaryDownloadActions(boundary, node.name)));
