export function overlapsDateRange(
  start: string | null,
  end: string | null,
  activityStart: string | null,
  activityEnd: string | null,
) {
  // String date comparison
  // Returns true if an area has no activity at all, no date range is set, or the activity date is within the range

  // no filter
  if (!start && !end) {
    return true;
  }

  // no activity
  if (!activityStart && !activityEnd) {
    return false;
  }

  // Only the start date is set
  if (start && !end) {
    return activityStart >= start || activityEnd >= start;
  }

  // Only the end date is set
  if (!start && end) {
    return activityStart <= end || activityEnd <= end;
  }

  return (
    (activityStart >= start && activityStart <= end) ||
    (activityEnd >= start && activityEnd <= end)
  );
}
