import { Injectable, inject } from '@angular/core';
import {
  EntityState,
  EntityStore,
  QueryConfig,
  QueryEntity,
  StoreConfig,
} from '@datorama/akita';
import { YYYYMMDD } from '@dendra/utils';
import { TreeNode } from '@services/tree.service';
import { of } from 'rxjs';

// Feature class keyed by location & survey period
export type FeatureClass = {
  id: string; // `${featureclass_id}-${location_id}-${survey_period}`
  classId: number;
  title: string;
  subtitle: string;
  survey_period: YYYYMMDD;
  category?: string;
  location: number;
  color: string;
};

interface FeatureClassState extends EntityState<FeatureClass, string> {}

@StoreConfig({ name: 'tree-node-feature-class' })
@Injectable({ providedIn: 'root' })
export class FeatureClassStore extends EntityStore<FeatureClassState> {
  constructor() {
    super();
  }
}

@QueryConfig({ sortBy: 'name' })
@Injectable({ providedIn: 'root' })
export class FeatureClassQuery extends QueryEntity<FeatureClassState> {
  protected store: FeatureClassStore;

  constructor() {
    const store = inject(FeatureClassStore);

    super(store);
    this.store = store;
  }
}

@Injectable({ providedIn: 'root' })
export class FeatureClassService {
  protected store = inject(FeatureClassStore);
  query = inject(FeatureClassQuery);

  getOrSelect(node: TreeNode) {
    if (!this.query.hasEntity(node.id)) {
      // The entity isn't fetched from anywhere, but constructed from the node
      this.store.add({
        id: node.id,
        classId: node.objectId,
        title: node.name,
        subtitle: node.extraData.subtitle,
        survey_period: node.surveyPeriod,
        location: node.extraData.location,
        color: node.extraData.color,
      });
    }

    return of(this.query.getEntity(node.id));
  }
}
