import { Injectable, inject } from '@angular/core';
import {
  EntityState,
  EntityStore,
  QueryEntity,
  StoreConfig,
} from '@datorama/akita';
import { NgEntityService } from '@datorama/akita-ng-entity-service';

export type DownloadableResource = {
  id?: number;
  resource_type_display: string;
  url: string;
};

interface DownloadableResourceState
  extends EntityState<DownloadableResource, number> {}

@StoreConfig({ name: 'downloadable-resource' })
@Injectable({ providedIn: 'root' })
export class DownloadableResourceStore extends EntityStore<DownloadableResourceState> {
  constructor() {
    super();
  }
}

@Injectable({ providedIn: 'root' })
export class DownloadableResourceQuery extends QueryEntity<DownloadableResourceState> {
  protected store: DownloadableResourceStore;

  constructor() {
    const store = inject(DownloadableResourceStore);

    super(store);
    this.store = store;
  }
}

@Injectable({ providedIn: 'root' })
export class DownloadableResourceService extends NgEntityService<DownloadableResourceState> {
  store: DownloadableResourceStore;
  query = inject(DownloadableResourceQuery);

  constructor() {
    const store = inject(DownloadableResourceStore);

    super(store);
    this.store = store;
  }

  listForLayer = (layer: number) =>
    this.get<DownloadableResource[]>({
      urlPostfix: 'list-for-layer',
      params: { skai_feature: layer.toString() },
    });
}
