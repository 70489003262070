import { TreeNodeTypeConfig } from '@services/tree.service';
import { defaultConfig } from '../../tree-nodes/default.config';
import { ChangeMapLegendItemComponent } from '../legend/change-map-legend-item/change-map-legend-item.component';

export const changeMapConfig: TreeNodeTypeConfig = {
  ...defaultConfig,
  isSelectable: true,
  isCountable: false,
  nodeStyling: 'card-child',
  getTreeTitle: () => 'Change map',
  urlParamName: 'cm',
  getUrlKey: node => node.name,
  treeInfoTooltip:
    'A change map visualises the difference between 2 survey periods',

  legendItemComponent: ChangeMapLegendItemComponent,
};
