import type { SKAIFeatureRenderOptions } from '@dendra/entity-skai-feature';
import { canCalculateArea, isFeatureCollection } from '@dendra/utils';
import turfArea from '@turf/area';
import { AllGeoJSON, Feature } from '@turf/helpers';

export const getArea = turfArea;

type LegendItem = {
  key: string;
  color: string;
};

/**
 * Maps the legend object stored on a SKAI feature to an ordered list of legend items.
 */
export const legendEntries = (legend: {
  [key: string]: string;
}): LegendItem[] => {
  const sorted = Object.entries(legend).sort((a, b) => {
    // TODO This is a hack to implement consistently sorted legend keys for area classification. The true
    // fix is to use sorted 2-tuples for legend instead of object which was a poor design choice.
    const keyA = a[0].substring(a[0].indexOf(':') + 1);
    const keyB = b[0].substring(b[0].indexOf(':') + 1);
    return keyA.localeCompare(keyB);
  });
  return sorted.map(item => ({ key: item[0], color: item[1] }));
};

export type AdditionalLegendProps = {
  labelCount?: number;
  area?: number;
};

export const getAdditionalVectorLayerProps = (
  opts: Pick<SKAIFeatureRenderOptions, 'showFeatureCount' | 'showArea'>,
  geojson: AllGeoJSON,
) => {
  return {
    labelCount:
      opts.showFeatureCount && isFeatureCollection(geojson)
        ? geojson.features.length
        : undefined,
    area:
      opts.showArea && canCalculateArea(geojson)
        ? getArea(geojson as Feature)
        : undefined,
  };
};
