@if (featureClass$ | async; as featureClass) {
  <div class="legend-item legend-item--class">
    <div class="legend-item__body" [style.border-color]="featureClass.color">
      <div class="d-flex gap-1">
        <div>
          <div class="legend-item__name">{{ featureClass.title }}</div>
          <div class="body-sm text-muted">
            {{ featureClass.subtitle }}
          </div>
        </div>

        @if (
          (mapControlService.zoom$ | async) <
            HIGH_FEATURE_COUNT_ZOOM_THRESHOLD && featureClass.highFeatureCount
        ) {
          <span>
            <mydendra-icon
              data-test-id="warning-icon"
              class="text-warning"
              name="warning"
              size="xxs"
              placement="left"
              container="body"
              [adaptivePosition]="false"
              tooltip="This class has a high result count. Results may be downsampled. Zoom in to see full detail."
            ></mydendra-icon>
          </span>
        }
      </div>
      <div class="legend-item__detail">
        <div class="legend-item__detail__content">
          @if (featureClass.count || featureClass.populationEstimate) {
            <div class="legend-item__class-count">
              {{ featureClass.countLabel }}:
              {{
                featureClass.populationEstimate || featureClass.count
                  | number: '1.0-0'
              }}
              @if (featureClass.area) {
                <span>(≈ {{ featureClass.area | area }})</span>
              }
            </div>
          }
        </div>
        <div class="legend-item__detail-meta">
          <div class="legend-item__survey-period">
            {{ (featureClass.survey_period | date: 'MMM y') || '---' }}
          </div>
        </div>
      </div>
      @if (featureClass.populationEstimate) {
        <div class="d-flex flex-column gap-1">
          <div class="legend-item__estimate-range d-flex gap-1">
            <span class="range">
              &plusmn;{{
                featureClass.populationEstimateRange | number: '1.0-0'
              }}%
            </span>
            <div class="bounds">
              ({{ featureClass.populationLowerBounds | number: '1.0-0' }}
              &mdash;
              {{ featureClass.populationUpperBounds | number: '1.0-0' }})
            </div>
          </div>
        </div>
      }
      @if (treeNode.extraData?.methodology === 'WEEDS_L1') {
        <div class="alert alert-warning small p-1 mt-1 mb-0 body-sm">
          An infestation is a ~10x10m quadrat containing at least one weed.
          Statistics will differ from surveys tagged using ~2x2m quadrats.
        </div>
      }
    </div>
  </div>
}
