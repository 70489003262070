import { formatDate } from '@angular/common';
import { HeatMapLegendItemComponent } from '@mydendra/portal/legend/heat-map-legend-item/heat-map-legend-item.component';
import { makeHeatMapUrlKey } from '@mydendra/utils/insights';
import { TreeNodeTypeConfig } from '@services/tree.service';
import { defaultConfig } from '../../tree-nodes/default.config';

export const heatMapConfig: TreeNodeTypeConfig = {
  ...defaultConfig,
  isSelectable: true,
  nodeStyling: 'card-child',
  getTreeTitle: node => formatDate(node.surveyPeriod, 'MMM y', 'en-US'),
  legendItemComponent: HeatMapLegendItemComponent,
  getIcon: () => 'grid-lg',
  urlParamName: 'hm',
  getUrlKey: node =>
    makeHeatMapUrlKey({
      location: node.extraData.location,
      analysisType: node.extraData.analysisType,
      surveyPeriod: node.surveyPeriod,
      category: node.extraData.indexCategory,
    }),
};
