import { getFeatureColor } from '@dendra/utils';
import { TreeNodeTypeConfig } from '@services/tree.service';
import { defaultConfig } from '../../tree-nodes/default.config';
import { NodeContextMenuComponent } from './node-context-menu/context-menu.component';

export const locationBoundaryConfig: TreeNodeTypeConfig = {
  ...defaultConfig,
  nodeStyling: 'card',
  urlParamName: 'lb',
  isSelectable: true,
  getColor: node => getFeatureColor(`location-boundary-${node.objectId}`),
  treeInfoTooltip:
    'The Managed Area shows the union of all survey boundaries for this area',
  contextMenuComponent: NodeContextMenuComponent,
};
