import { formatDate } from '@angular/common';
import { rotateFeatureClassColor } from '@dendra/utils';
import { makeFeatureClassUrlKey } from '@mydendra/utils/insights';
import { TreeNodeTypeConfig } from '@services/tree.service';
import { defaultConfig } from '../../tree-nodes/default.config';
import { FeatureClassLegendItemComponent } from '../legend/feature-class/feature-class.component';
import { NodeContextMenuComponent } from './node-context-menu/context-menu.component';

export const featureClassConfig: TreeNodeTypeConfig = {
  ...defaultConfig,
  nodeStyling: 'card-child',
  isSelectable: true,

  getTreeTitle: node => formatDate(node.surveyPeriod, 'MMM y', 'en-US'),
  getColor: node =>
    rotateFeatureClassColor(
      node.objectId,
      node.extraData.color,
      node.surveyPeriod,
    ),
  urlParamName: 'fc',
  getUrlKey: node => makeFeatureClassUrlKey(node),
  legendItemComponent: FeatureClassLegendItemComponent,
  contextMenuComponent: NodeContextMenuComponent,
};
