import type { BaseLegendItemComponent } from '../legend.types';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { rotateFeatureClassColor } from '@dendra/utils';
import { FeatureClassStatsService } from '@mydendra/states/feature-class-stats';
import {
  FeatureClass,
  FeatureClassService,
} from '@mydendra/states/node-feature-class';
import { MapControlService } from '@services/map-control.service';
import { TreeNode } from '@services/tree.service';
import { SharedComponentsModule } from '@shared/components';
import { SharedPipesModule } from '@shared/pipes';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { map, Observable, switchMap } from 'rxjs';

type AnnotatedClass = FeatureClass & {
  count: number | null;
  countLabel: string;
  color: string;
  area: number | null;
  highFeatureCount: boolean;
  populationEstimate?: number;
  populationLowerBounds?: number;
  populationUpperBounds?: number;
  populationEstimateRange?: number;
};

@Component({
  templateUrl: './feature-class.component.html',
  styleUrls: ['../legend-item.core.scss', './feature-class.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    TooltipModule,
    SharedPipesModule,
    SharedComponentsModule,
  ],
})
export class FeatureClassLegendItemComponent
  implements BaseLegendItemComponent
{
  private featureClassService = inject(FeatureClassService);
  private statsService = inject(FeatureClassStatsService);
  featureClass$: Observable<AnnotatedClass>;
  treeNode: TreeNode;
  mapControlService = inject(MapControlService);
  HIGH_FEATURE_COUNT_ZOOM_THRESHOLD = 18;

  @Input() set node(input: TreeNode) {
    this.treeNode = input;
    this.featureClass$ = this.featureClassService.getOrSelect(input).pipe(
      switchMap(featureClass =>
        this.statsService
          .getStats(
            featureClass.location,
            featureClass.survey_period,
            featureClass.classId,
          )
          .pipe(
            map(stats => ({
              ...featureClass,
              count: stats.count,
              countLabel: stats.count_label,
              area: stats.area,
              highFeatureCount: stats.high_feature_count,
              color: rotateFeatureClassColor(
                featureClass.classId,
                featureClass.color,
                featureClass.survey_period,
              ),
              populationEstimate: stats.total_population_estimate,
              populationLowerBounds: stats.total_population_lower_bound,
              populationUpperBounds: stats.total_population_upper_bound,
              populationEstimateRange:
                this.statsService.calculateEstimatePercentRange(stats),
            })),
          ),
      ),
    );
  }
}
