import type { BaseLegendItemComponent } from '../legend.types';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Input,
  inject,
} from '@angular/core';
import { RGBArray, rgbArrayToHex } from '@dendra/utils';
import { analysisConfigs } from '@mydendra/portal/analysis-configs/configs';
import { MapControlService } from '@services/map-control.service';
import { TreeNode } from '@services/tree.service';
import { SharedComponentsModule } from '@shared/components';
import { Observable, map, distinctUntilChanged } from 'rxjs';

@Component({
  templateUrl: './heat-map-legend-item.component.html',
  styleUrls: [
    './heat-map-legend-item.component.scss',
    '../legend-item.core.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, SharedComponentsModule],
})
export class HeatMapLegendItemComponent
  implements OnInit, BaseLegendItemComponent
{
  @Input() node: Pick<
    TreeNode,
    'id' | 'extraData' | 'name' | 'surveyPeriod' | 'objectId'
  >;
  mapControlService = inject(MapControlService);

  isDetailView$: Observable<boolean>;

  ngOnInit() {
    this.isDetailView$ = this.mapControlService.zoom$.pipe(
      map(zoom => zoom >= this.analysisConfig.fadeMax - 0.5),
      distinctUntilChanged(),
    );
  }

  get analysisConfig() {
    return analysisConfigs[this.node.extraData.analysisType];
  }

  get colorStyle() {
    return this.analysisConfig.getColorStyle(this.node);
  }

  legendEntries() {
    const analysisColorType: 'match' | 'step' = this.colorStyle[0] as
      | 'match'
      | 'step';

    if (analysisColorType === 'match') {
      return this.matchColorLegendEntries();
    }

    return this.stepColorLegendEntries();
  }

  matchColorLegendEntries() {
    // First two items are the interpolation type, and the getter
    // Then it alternates between title and color
    const items = this.colorStyle.slice(2, -1);
    const legend = [];

    for (let i = 0; i < items.length; i += 2) {
      const color = items[i + 1] as RGBArray;
      legend.push({
        title: items[i],
        color: rgbArrayToHex(color.slice(1) as RGBArray),
      });
    }

    return legend;
  }

  stepColorLegendEntries = () =>
    this.analysisConfig.getLegendEntries(this.colorStyle);
}
