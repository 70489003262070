import type { BaseLegendItemComponent } from '../legend.types';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { RGBArray, rgbArrayToHex } from '@dendra/utils';
import { analysisConfigs } from '@mydendra/portal/analysis-configs/configs';
import { MapControlService } from '@services/map-control.service';
import { TreeNode } from '@services/tree.service';
import { SharedComponentsModule } from '@shared/components';
import { ExpressionSpecification } from 'maplibre-gl';
import { Observable, distinctUntilChanged, map } from 'rxjs';

@Component({
  templateUrl: './change-map-legend-item.component.html',
  styleUrls: [
    './change-map-legend-item.component.scss',
    '../legend-item.core.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, SharedComponentsModule],
})
export class ChangeMapLegendItemComponent
  implements OnInit, BaseLegendItemComponent
{
  @Input() node: TreeNode;
  mapControlService = inject(MapControlService);

  detailView$: Observable<boolean>;

  ngOnInit() {
    this.detailView$ = this.mapControlService.zoom$.pipe(
      map(zoom => zoom >= this.analysisConfig.fadeMax - 0.5),
      distinctUntilChanged(),
    );
  }

  get analysisConfig() {
    return analysisConfigs[this.node.extraData.analysisType];
  }

  get colorStyle() {
    return this.analysisConfig.getColorStyle(this.node);
  }

  private colorsFromStyle(style: ExpressionSpecification) {
    // First two items are the iterpolation type, and the getter
    // Then it is color, followed by value. We want the odd items
    const colors = style.slice(2).filter((_, idx) => idx % 2 === 0) as [
      string,
      number,
      number,
      number,
      number,
    ][];
    return colors.map(color => rgbArrayToHex(color.slice(1) as RGBArray));
  }

  // todo: pull out colour domains & title formatting into per-metric configs
  legendEntries() {
    const colors = this.colorsFromStyle(this.colorStyle);

    return [
      { title: '&gt; 1m<sup>3</sup> increase', color: colors[4] },
      { title: '0.5 to 1m<sup>3</sup> increase', color: colors[3] },
      { title: '< 0.5 m<sup>3</sup> change', color: colors[2] },
      { title: '0.5 to 1m<sup>3</sup> decrease', color: colors[1] },
      { title: '&gt; 1m<sup>3</sup> decrease', color: colors[0] },
    ];
  }

  legendSummaryEntries() {
    const colors = this.colorsFromStyle(
      this.analysisConfig.secondaryColorStyle,
    );

    return [
      { title: 'Significant increase', color: colors[4] },
      { title: 'Moderate increase', color: colors[3] },
      { title: 'Moderate decrease', color: colors[1] },
      { title: 'Significant decrease', color: colors[0] },
    ];
  }
}
