import { inject } from '@angular/core';
import { TreeNodeTypeConfig, TreeService } from '@services/tree.service';
import { defaultConfig } from '../../tree-nodes/default.config';

export const categoryGroupConfig: TreeNodeTypeConfig = {
  ...defaultConfig,
  isSubgroup: true,
  nodeStyling: 'subgroup',
  urlParamName: null,
  getTreeTitle: node => {
    const childCount = node.childIds.length;
    return `${node.name} (${childCount})`;
  },
  isSelectable: true,
  childrenActivationBehavior: {
    isActive: (node, activeIds) => {
      const children = inject(TreeService).query.getAll({
        filterBy: child => child.parentId == node.id,
      });
      return children.some(
        childNode =>
          activeIds.includes(childNode.id) ||
          childNode.childIds.some(grandChildId =>
            activeIds.includes(grandChildId),
          ),
      );
    },

    isDeterminate: () => true,
    idsToToggle: node => node.childIds,
  },
};
