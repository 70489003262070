import { inject } from '@angular/core';
import { sortByKey } from '@dendra/utils';
import { AllAnalysisTypes } from '@mydendra/utils/stats.types';
import {
  TreeNodeResponse,
  TreeNodeTypeConfig,
  TreeService,
} from '@services/tree.service';
import { defaultConfig } from '../../tree-nodes/default.config';
import { portalTreeConfig } from './configs';
import { NodeContextMenuComponent } from './node-context-menu/context-menu.component';

const changeMapSupportedTypes: AllAnalysisTypes[] = ['erosion'];

// Add a ChangeMap node if the node has multiple children with analysis type that we support change maps for
// todo: validate whether multiple analyses for a single survey group could be needed
const changeMapNodeFor = (node: TreeNodeResponse): TreeNodeResponse | null => {
  const childrenWithAnalysis = node.children.filter(child =>
    child.extraData?.analysisTypesAvailable?.some(type =>
      changeMapSupportedTypes.includes(type),
    ),
  );
  if (childrenWithAnalysis.length < 2) {
    return;
  }
  const firstChild = childrenWithAnalysis[0];
  return {
    id: `${node.id}-changemap`,
    name: firstChild.name,
    category: null,
    objectType: 'ChangeMap',
    parentId: node.id,
    urlForChildren: null,
    surveyPeriod: null,
    surveyType: null,
    extraData: {
      sourceLayers: childrenWithAnalysis
        .sort(sortByKey('surveyPeriod'))
        .map(({ surveyPeriod, objectId: id }) => ({ id, surveyPeriod })),
      location: firstChild.extraData.location,
      analysisType: firstChild.extraData.analysisTypesAvailable[0],
    },
    children: [],
  };
};

export const surveyGroupConfig: TreeNodeTypeConfig = {
  ...defaultConfig,
  nodeStyling: 'card',
  urlParamName: null,
  getTreeSubtitle: node => node.extraData?.subtitle,
  getSurveyPeriod: node => {
    const children = inject(TreeService).getChildren(node);
    if (children.length === 1) {
      return children[0].surveyPeriod;
    }
    return node.surveyPeriod;
  },
  isSelectable: true,
  getChildren: node =>
    [...node.children, changeMapNodeFor(node)].filter(Boolean),
  getColor: node => {
    // Returns the colour of the first active child of the node, or if none are active, the first child
    const treeService = inject(TreeService);
    const children = treeService.getChildren(node);
    const child =
      children.filter(({ id }) => treeService.query.hasActive(id))[0] ||
      children[0];
    if (child) {
      return portalTreeConfig[child.objectType].getColor(child);
    }
  },
  getIcon: node => {
    // Returns the icon of the first child node
    const child = inject(TreeService).getChildren(node)[0];
    if (child) {
      return portalTreeConfig[child.objectType].getIcon(child);
    }
  },
  childrenActivationBehavior: {
    isActive: (node, activeIds) =>
      node.childIds.some(id => activeIds.includes(id)),
    isDeterminate: () => true,
    idsToToggle: (node, activeIds) => {
      const activeChildIds = node.childIds.filter(childId =>
        activeIds.includes(childId),
      );

      if (activeChildIds.length) {
        return activeChildIds;
      }
      return [node.childIds[0]];
    },
  },
  contextMenuComponent: NodeContextMenuComponent,
};
