import { formatDate } from '@angular/common';
import { isVectorLayer } from '@dendra/entity-renderable-skai-feature';
import {
  getFeatureColor,
  rgbToHex,
  rotateFeatureClassColor,
} from '@dendra/utils';
import { SKAIFeatureType } from '@mydendra/states/renderable-skai-feature';
import { TreeNode, TreeNodeTypeConfig } from '@services/tree.service';
import { defaultConfig } from '../../tree-nodes/default.config';
import { SKAIFeatureLegendItemComponent } from '../legend/skai-feature/skai-feature.component';
import { NodeContextMenuComponent } from './node-context-menu/context-menu.component';

export const skaiFeatureConfig: TreeNodeTypeConfig = {
  ...defaultConfig,

  nodeStyling: 'card-child',
  isSelectable: true,
  urlParamName: 'layer',

  getTreeTitle: node => formatDate(node.surveyPeriod, 'MMM y', 'en-US'),

  getColor: node =>
    isVector(node) // It may prove better to split this node type in two
      ? node.extraData.renderOptions?.style?.color
        ? rgbToHex(node.extraData.renderOptions.style.color)
        : getLayerColor(node)
      : null,

  getIcon: node => (isVector(node) ? null : 'image'),

  legendItemComponent: SKAIFeatureLegendItemComponent,
  contextMenuComponent: NodeContextMenuComponent,

  loadsData: node => isVector(node),
};

const isVector = (node: TreeNode) =>
  isVectorLayer({
    featureType: node.extraData.featureType as SKAIFeatureType,
  });

const getLayerColor = (node: TreeNode) => {
  if (node.extraData.featureClass) {
    // It's a feature-class-layer, use the same colour as we would for that class
    return rotateFeatureClassColor(
      node.extraData.featureClass,
      node.extraData.color,
      node.surveyPeriod,
    );
  }
  return getFeatureColor(node.objectId);
};
