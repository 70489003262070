import { NodeObjectType, TreeNodeTypeConfig } from '@services/tree.service';
import { defaultConfig } from '../../tree-nodes/default.config';
import { boundaryConfig } from './boundary.config';
import { categoryGroupConfig } from './category-group.config';
import { changeMapConfig } from './change-map.config';
import { featureClassConfig } from './feature-class.config';
import { heatMapConfig } from './heat-map.config';
import { locationBoundaryConfig } from './location-boundary.config';
import { skaiFeatureConfig } from './skai-feature.config';
import { surveyGroupConfig } from './survey-group.config';
import { targetAreaBoundaryConfig } from './target-area-boundary.config';
import { wrapperConfig } from './wrapper.config';

// TODO: each app should define which node types it cares about
export const portalTreeConfig: Record<NodeObjectType, TreeNodeTypeConfig> = {
  Wrapper: wrapperConfig,
  Location: { ...defaultConfig, urlParamName: 'loc' },
  DataCategory: { ...defaultConfig, urlParamName: 'dc' },
  Project: { ...defaultConfig, urlParamName: 'project' },
  Dataset: { ...defaultConfig, urlParamName: 'ds' },
  SKAIFeature: skaiFeatureConfig,
  FeatureClass: featureClassConfig,
  SurveyGroup: surveyGroupConfig,
  CategoryGroup: categoryGroupConfig,
  ChangeMap: changeMapConfig,
  HeatMap: heatMapConfig,
  SurveyBoundary: boundaryConfig,
  LocationBoundary: locationBoundaryConfig,
  TargetAreaBoundary: targetAreaBoundaryConfig,
};
