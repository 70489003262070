<context-menu
  #contextMenu
  menuClass="context-menu"
  (open)="lazyLoadContextMenuItems()"
>
  <ng-template contextMenuItem (execute)="panToLayer()">
    Pan to layer
  </ng-template>
  <ng-template
    contextMenuItem
    [visible]="
      loadingContextMenuItems || (downloadableResources | async)?.length > 0
    "
    [subMenu]="downloadOptions"
  >
    @if ((downloadableResources | async)?.length > 0) {
      <span class="d-flex"> Download layer... </span>
    }
    @if (loadingContextMenuItems) {
      <span class="loading-menu-item">
        Loading...
        <mydendra-icon
          name="spinner"
          class="loading-icon"
          [spin]="true"
        ></mydendra-icon>
      </span>
    }
  </ng-template>
</context-menu>

<span [contextMenu]="contextMenu" class="d-none"></span>

<context-menu #downloadOptions>
  @for (resource of downloadableResources | async; track resource) {
    <ng-template contextMenuItem (execute)="download(resource)">
      Download {{ resource.title }}
    </ng-template>
  }
</context-menu>
