@let isDetailView = isDetailView$ | async;

@if (analysisConfig) {
  <div class="legend-item legend-item--feature">
    <div class="legend-item__body no-color">
      <div class="legend-item__name">
        {{ analysisConfig.legendItemTitle ?? node.name }}
      </div>
      @if (analysisConfig.legendItemSubtitle !== null) {
        <div class="legend-item__subtitle">
          {{ analysisConfig.legendItemSubtitle }}
        </div>
      }
      <div class="legend-item__detail">
        <div class="legend-item__detail__content">
          @for (entry of legendEntries(); track entry) {
            <div
              class="legend-item__feature-row"
              [style.border-color]="entry.color"
              [innerHTML]="entry.title"
            ></div>
          }
        </div>
        <div class="legend-item__detail-meta">
          <div class="legend-item__survey-period">
            {{ node.surveyPeriod | date: 'MMM y' }}
          </div>
        </div>
      </div>
      @if (analysisConfig.zoomLevels.detail && !isDetailView) {
        <div class="body-sm text-muted mt-2 d-flex align-items-start">
          <mydendra-icon
            name="info-context"
            size="sm"
            class="d-inline"
          ></mydendra-icon>
          Zoom in for detailed view
        </div>
      }
    </div>
  </div>
}
