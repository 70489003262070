<div class="legend-item legend-item--feature">
  <div class="legend-item__body no-color">
    <div class="legend-item__name">
      {{ analysisConfig.legendItemTitle ?? node.name + ': changes' }}
    </div>
    @if (analysisConfig.legendItemSubtitle !== null) {
      <div class="legend-item__subtitle" [innerHTML]>
        {{ analysisConfig.legendItemSubtitle }}
      </div>
    }
    <div class="legend-item__detail">
      @if (detailView$ | async) {
        <div class="legend-item__detail__content">
          @for (entry of legendEntries(); track entry) {
            <div
              class="legend-item__feature-row"
              [style.border-color]="entry.color"
              [innerHTML]="entry.title"
            ></div>
          }
        </div>
      } @else {
        <div class="legend-item__detail__content">
          @for (entry of legendSummaryEntries(); track entry) {
            <div
              class="legend-item__feature-row"
              [style.border-color]="entry.color"
              [innerHTML]="entry.title"
            ></div>
          }
          <div class="body-sm text-muted mt-2 d-flex align-items-start">
            <mydendra-icon
              name="info-context"
              size="sm"
              class="d-inline"
            ></mydendra-icon>
            Zoom in for detailed change data
          </div>
        </div>
      }
      <div class="legend-item__detail-meta">
        <div class="legend-item__survey-period">
          {{ node.extraData.sourceLayers.at(-2)?.surveyPeriod | date: 'MMM y' }}
          to
          {{ node.extraData.sourceLayers.at(-1).surveyPeriod | date: 'MMM y' }}
        </div>
      </div>
    </div>
  </div>
</div>
