import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { TreeNodeResponse, TreeNodeTypeConfig } from '@services/tree.service';

export const defaultFetchChildren: TreeNodeTypeConfig['fetchChildren'] = node =>
  inject(HttpClient).get<TreeNodeResponse[]>(node.urlForChildren);

export const defaultConfig: Omit<TreeNodeTypeConfig, 'urlParamName'> = {
  nodeStyling: 'default',
  isSelectable: false,
  isCountable: true,
  childDisplayType: 'tree-node',
  getTreeTitle: node => node.name,
  getTreeSubtitle: () => '',
  getColor: () => null,
  getIcon: () => null,
  getSurveyPeriod: node => node.surveyPeriod,
  fetchChildren: defaultFetchChildren,
  getChildren: node => node.children || [],
  getUrlKey: node => node.objectId?.toString(),
  legendItemComponent: null,
  contextMenuComponent: null,
  childrenActivationBehavior: {
    isActive: (node, activeIds) =>
      node.childIds.length
        ? node.childIds.every(id => activeIds.includes(id))
        : activeIds.includes(node.id),
    isDeterminate: (node, activeIds) =>
      node.childIds.every(id => activeIds.includes(id)) ||
      node.childIds.every(id => !activeIds.includes(id)),
    idsToToggle: () => [],
  },
};
