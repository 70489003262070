import { getFeatureColor } from '@dendra/utils';
import { TreeNodeTypeConfig } from '@services/tree.service';
import { defaultConfig } from '../../tree-nodes/default.config';
import { NodeContextMenuComponent } from './node-context-menu/context-menu.component';

export const boundaryConfig: TreeNodeTypeConfig = {
  ...defaultConfig,
  nodeStyling: 'card-child',
  urlParamName: 'sb',
  isSelectable: true,
  getColor: node => getFeatureColor(`survey-${node.objectId}`),
  contextMenuComponent: NodeContextMenuComponent,
};
