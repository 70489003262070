import type { BaseLegendItemComponent } from '../legend.types';
import type { MapControlService } from '@services/map-control.service';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { isVectorLayer } from '@dendra/entity-renderable-skai-feature';
import {
  AdditionalLegendProps,
  getAdditionalVectorLayerProps,
  legendEntries,
} from '@dendra/feature-legends';
import { rgbArrayToHex, rotateFeatureClassColor } from '@dendra/utils';
import {
  RenderableSKAIFeature,
  RenderableSKAIFeatureService,
  getColor,
} from '@mydendra/states/renderable-skai-feature';
import { TreeNode } from '@services/tree.service';
import { VectorLayerGeoJSONService } from '@services/vector-layer-geojson';
import { SharedPipesModule } from '@shared/pipes';
import { Observable, filter, map, of, switchMap } from 'rxjs';

type AnnotatedFeature = RenderableSKAIFeature &
  AdditionalLegendProps & {
    color: string;
  };

@Component({
  templateUrl: './skai-feature.component.html',
  styleUrls: ['../legend-item.core.scss', './skai-feature.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, SharedPipesModule],
})
export class SKAIFeatureLegendItemComponent implements BaseLegendItemComponent {
  private renderableSkaiFeatureService = inject(RenderableSKAIFeatureService);
  private vectorLayerService = inject(VectorLayerGeoJSONService);
  skaiFeature$: Observable<AnnotatedFeature>;

  @Input() set node(input: TreeNode) {
    this.skaiFeature$ = this.renderableSkaiFeatureService
      .getOrSelect(input.objectId)
      .pipe(
        filter(
          layer =>
            Object.keys(layer.legend).length > 0 ||
            Boolean(layer.description) ||
            isVectorLayer(layer),
        ),
        switchMap(layer => {
          if (isVectorLayer(layer)) {
            const color =
              layer.featureType === 'feature-class-layer'
                ? rotateFeatureClassColor(
                    input.extraData.featureClass,
                    input.extraData.color,
                    input.surveyPeriod,
                  )
                : rgbArrayToHex(getColor(layer));
            return this.vectorLayerService.query.selectEntity(layer.id).pipe(
              filter(Boolean),
              map(({ geojson }) => ({
                ...layer,
                ...getAdditionalVectorLayerProps(layer.renderOptions, geojson),
                color,
              })),
            );
          } else {
            return of({
              ...layer,
              color: 'transparent',
            });
          }
        }),
      );
  }
  @Input() mapControlService: MapControlService;

  legendEntries = legendEntries;
}
