@if (skaiFeature$ | async; as feature) {
  <div class="legend-item legend-item--feature">
    <div
      class="legend-item__body"
      [style.border-color]="feature.color"
      [class.no-color]="feature.color === 'transparent'"
    >
      <span class="legend-item__name">{{ feature.name }}</span>
      <div class="legend-item__detail">
        <div class="legend-item__detail__content">
          @for (entry of legendEntries(feature.legend); track entry) {
            <div
              class="legend-item__feature-row"
              [style.border-color]="entry.color"
              [innerHTML]="entry.key | safe"
            ></div>
          }
          @if (feature.description) {
            <div
              [innerHTML]="feature.description | safe"
              class="legend-item__description"
            ></div>
          }
          @if (feature.labelCount) {
            <div class="legend-item__description">
              Count: {{ feature.labelCount | number }}
            </div>
          }
          @if (feature.area) {
            <div class="legend-item__description">
              Area: {{ feature.area | area }}
            </div>
          }
        </div>
        <div class="legend-item__detail-meta">
          <div class="legend-item__survey-period">
            {{ (feature.dataCaptureTimestamp | date: 'MMM y') || '---' }}
          </div>
        </div>
      </div>
    </div>
  </div>
}
